import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DocumentData } from 'firebase/firestore';

import { AssignmentResult, Results } from '../utilities/types';
import { RootState } from './store';

interface ResultsState {
  currentResults: Results | null | DocumentData;
}

const initialState: ResultsState = {
  currentResults: null,
};

const ResultsSlice = createSlice({
  name: 'resultsSlice',
  initialState,
  reducers: {
    setResults: (state, action: PayloadAction<ResultsState>) => {
      state.currentResults = action.payload.currentResults || null;
    },
    setAssignmentsResults: (state, action: PayloadAction<AssignmentResult[]>) => {
      state.currentResults = {
        ...state.currentResults,
        assignmentsResults: action.payload,
      };
    },

    setResultsScores: (
      state,
      action: PayloadAction<{ publicScore: number; privateScore: number; nrOfPublicSubmittedDays: number }>,
    ) => {
      state.currentResults = {
        ...state.currentResults,
        publicScore: action.payload.publicScore,
        privateScore: action.payload.privateScore,
        nrOfDaysSubmitted: action.payload.nrOfPublicSubmittedDays,
      };
    },

    setBadgesResults: (state, action: PayloadAction<AssignmentResult[]>) => {
      state.currentResults = {
        ...state.currentResults,
        badges: action.payload,
      };
    },
    setResultsGoal: (state, action: PayloadAction<number | null>) => {
      state.currentResults = {
        ...state.currentResults,
        goal: action.payload,
      };
    },
    unsetResults: (state) => {
      state.currentResults = null;
    },
  },
});

export const getCurrentResults = (state: RootState) => state.resultsSlice.currentResults;

export const { setResults, setAssignmentsResults, setBadgesResults, setResultsGoal, unsetResults, setResultsScores } =
  ResultsSlice.actions;

export default ResultsSlice.reducer;
