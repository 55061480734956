// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Alert, Checkbox, FormControlLabel, IconButton, List, ListItemText, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Loader from '../../../components/Loader';
import MainContainer from '../../../components/MainContainer';
import TopBar from '../../../components/TopBar';
import DialogAssignmentSimple from '../../../components/dialogs/DialogAssignmentSimple';
import ListCardItem from '../../../components/stylish/ListCardItem';
import { readCurrentDeviceState } from '../../../store/appSlice';
import { getCurrentJoinCompetitionData, setJoinCompetitionData } from '../../../store/joinCompetitionSlice';
import JoinCompetitionButtons from './JoinCompetitionButtons';

type Step1ChooseAssignmentsScreenProps = {};

const Step1ChooseAssignmentsScreen: React.FC<Step1ChooseAssignmentsScreenProps> = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const isDesktop = useSelector(readCurrentDeviceState);
  const currentJoinCompetitionData = useSelector(getCurrentJoinCompetitionData);

  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [selectedAssignment, setSelectedAssignment] = useState<{ assignment: any; dbIndex: number } | null>(null);
  const [displayDialog, setDisplayDialog] = useState<boolean>(false);
  const [open, setOpen] = React.useState(true);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [selectAll, setSelectAll] = useState<boolean>(false);

  /**
   *  Lifecycle
   */
  useEffect(() => {
    if (!currentJoinCompetitionData) {
      return navigate('/app/utmaningar');
    }

    setIsLoading(false);
  }, [currentJoinCompetitionData, navigate]);

  /**
   *
   *
   * Creates
   *
   */
  const proceedToNextStep = (): void => {
    const nrOfChosenAssignments = currentJoinCompetitionData?.assignmentsDetails?.filter(
      (assignment: any) => assignment.displayAssignment,
    ).length;

    if (nrOfChosenAssignments < 3) {
      setErrorMsg('Du måste välja minst 3 aktiviteter.');
    } else {
      const assignmentsResults = currentJoinCompetitionData?.assignmentsDetails?.map((_assignment: any) => ({
        ..._assignment,
        id: _assignment.id,
        points: [0],
        displayAssignment: _assignment.displayAssignment,
        type: _assignment.type,
      }));

      dispatch(
        setJoinCompetitionData({
          currentJoinCompetitionData: {
            ...currentJoinCompetitionData,
            assignmentsResults: assignmentsResults,
          },
        }),
      );

      if (currentJoinCompetitionData?.allowCustomAssignment) {
        navigate('/app/utmaning-egen-aktivitet-info');
      } else {
        if (currentJoinCompetitionData?.allowTeams) {
          navigate('/app/utmaning-valj-lag');
        } else {
          navigate('/app/utmaning-smeknamn');
        }
      }
    }
  };

  const onAssignmentChange = (id: string) => {
    const updatedAssignments = currentJoinCompetitionData?.assignmentsDetails.map((assignment: any) => {
      if (assignment.id === id) {
        return { ...assignment, displayAssignment: !assignment.displayAssignment };
      } else {
        return assignment;
      }
    });

    const nrOfChosenAssignments = updatedAssignments?.filter((assignment: any) => assignment.displayAssignment).length;

    if (nrOfChosenAssignments > 2) {
      setErrorMsg('');
    }

    dispatch(
      setJoinCompetitionData({
        currentJoinCompetitionData: {
          ...currentJoinCompetitionData,
          assignmentsDetails: updatedAssignments,
        },
      }),
    );
  };

  /**
   * When select all assignments
   */
  const handleSelectAllChange = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    const updatedAssignments = currentJoinCompetitionData?.assignmentsDetails.map((assignment: any) => ({
      ...assignment,
      displayAssignment: newSelectAll,
    }));

    dispatch(
      setJoinCompetitionData({
        currentJoinCompetitionData: {
          ...currentJoinCompetitionData,
          assignmentsDetails: updatedAssignments,
        },
      }),
    );

    if (newSelectAll) {
      setErrorMsg('');
    }
  };

  /**
   *
   * Other methods
   */
  const showDialogAssignment = (assignment: any, i: number) => {
    setSelectedAssignment({ assignment: assignment, dbIndex: i });
    setDisplayDialog(true);
  };

  const nrOfChosenAssignments = currentJoinCompetitionData?.assignmentsDetails?.filter(
    (assignment: any) => assignment.displayAssignment,
  ).length;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <DialogAssignmentSimple
            selectedAssignment={selectedAssignment}
            displayDialog={displayDialog}
            setDisplayDialog={setDisplayDialog}
          />

          <TopBar position="fixed" hasBackButton />

          <MainContainer contentMaxWidth={600}>
            <Box mt={22} mb={isDesktop ? 2 : 46}>
              <Typography color="primary" variant="h5" component="h1" mt={4} fontWeight={600}>
                {t('text_choose_assignments_join_competition')}
              </Typography>

              <Collapse in={open}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2, mt: 4 }}
                  severity="info"
                  variant="filled"
                >
                  <Typography color="primary" variant="body1">
                    {t('alert_change_assignments')}
                  </Typography>
                </Alert>
              </Collapse>

              <List sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                <ListCardItem isFirst styles={{ display: 'flex', flexDirection: 'row-reverse' }}>
                  <Box
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                      handleSelectAllChange(event);
                    }}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <FormControlLabel
                      control={<Checkbox />}
                      label={selectAll ? t('label_select_no_assignments') : t('label_select_all_assignments')}
                      labelPlacement="start"
                      data-testid="checkbox-select-all"
                      name="select-all"
                      checked={selectAll}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, mr: -1, color: 'text.primary' }}
                    />
                  </Box>
                </ListCardItem>
                {currentJoinCompetitionData?.assignmentsDetails &&
                  currentJoinCompetitionData?.assignmentsDetails.map((assignment: any, i: number) => {
                    if (assignment.type !== 'custom-undefined') {
                      return (
                        <ListCardItem
                          isLast={
                            i ===
                            (currentJoinCompetitionData?.allowCustomAssignment
                              ? currentJoinCompetitionData?.assignmentsDetails.length - 2
                              : currentJoinCompetitionData?.assignmentsDetails.length - 1)
                          }
                          key={assignment.id}
                          onClick={() => onAssignmentChange(assignment.id)}
                          hoverEffect
                        >
                          <IconButton
                            sx={{
                              mr: 1,
                              ml: -2,
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              showDialogAssignment(assignment, i);
                            }}
                          >
                            <InfoOutlinedIcon fontSize="medium" />
                          </IconButton>
                          <ListItemText
                            primary={
                              <Typography color="primary" variant="body1">
                                {assignment.title[i18n.language === 'en' ? 'en' : 'se']}
                              </Typography>
                            }
                            secondary={
                              <Typography color="primary" variant="body2" sx={{ fontSize: '0.7rem' }}>
                                {assignment.description[i18n.language === 'en' ? 'en' : 'se']}
                              </Typography>
                            }
                          />
                          <Checkbox
                            data-testid={`checkbox-assignment-${assignment.index}`}
                            name={assignment.id}
                            edge="end"
                            checked={assignment.displayAssignment ? true : false}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, mr: -1 }}
                            inputProps={{ 'aria-labelledby': 'label' }}
                          />
                        </ListCardItem>
                      );
                    } else return null;
                  })}
              </List>
            </Box>

            <JoinCompetitionButtons
              extraContent={
                <>
                  <Typography
                    color="primary"
                    variant="body1"
                    component="p"
                    sx={{ p: 2, pt: 0, textAlign: 'end', mb: 1 }}
                  >
                    {t('label_nr_of_assignments_selected')}
                    <span style={{ fontWeight: 700 }}>
                      {nrOfChosenAssignments +
                        (currentJoinCompetitionData?.allowCustomAssignment && nrOfChosenAssignments !== 0 ? -1 : 0)}
                    </span>
                  </Typography>
                  {errorMsg && errorMsg !== '' && (
                    <Typography variant="body1" color="error" sx={{ p: 2, pt: 0, textAlign: 'end', mb: 3 }}>
                      {errorMsg}
                    </Typography>
                  )}
                </>
              }
              isDesktop={isDesktop}
              proceedToNextStep={proceedToNextStep}
              showSkipButton={false}
            />
          </MainContainer>
        </>
      )}
    </>
  );
};

export default Step1ChooseAssignmentsScreen;
