import bronze from '../assets/badges/bronze.svg';
import gold from '../assets/badges/gold.svg';
import iron from '../assets/badges/iron.svg';
import silver from '../assets/badges/silver.svg';
import wood from '../assets/badges/wood.svg';
import { Badge } from './types';

export const resultBadges = [
  { index: 0, earned: false },
  { index: 1, earned: false },
  { index: 2, earned: false },
  { index: 3, earned: false },
  { index: 4, earned: false },
];

export const badgeConditions = (duration: number) => {
  return duration > 0
    ? resultBadges.map((badge, i) => {
        return Math.round((duration / 5) * 6.4 * (1 + i));
      })
    : [50, 100, 200, 500, 1000];
};

/**
 *
 * Badges
 *
 */
export const badges: Badge[] = [
  {
    img: wood,
    alt: 'wood badge',
    label: 'label_badge_points',
  },
  {
    img: iron,
    alt: 'iron badge',
    label: 'label_badge_points',
  },
  {
    img: bronze,
    alt: 'bronze badge',
    label: 'label_badge_points',
  },
  {
    img: silver,
    alt: 'silver badge',
    label: 'label_badge_points',
  },
  {
    img: gold,
    alt: 'gold badge',
    label: 'label_badge_points',
  },
];
