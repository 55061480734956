import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import EmojiEventsRoundedIcon from '@mui/icons-material/EmojiEventsRounded';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import LeaderboardRoundedIcon from '@mui/icons-material/LeaderboardRounded';
import ReorderOutlinedIcon from '@mui/icons-material/ReorderOutlined';

interface NavigationItem {
  route: string;
  iconActive: JSX.Element;
  iconInActive: JSX.Element;
  label: string;
}

const items = {
  tab_assignments: {
    route: '/app/aktiviteter',
    iconActive: <AssignmentTurnedInRoundedIcon sx={{ fontSize: 28 }} />,
    iconInActive: <AssignmentTurnedInOutlinedIcon sx={{ fontSize: 28 }} />,
    label: 'tab_assignments',
  },
  tab_achievements: {
    route: '/app/prestationer',
    iconActive: <EmojiEventsRoundedIcon sx={{ fontSize: 28 }} />,
    iconInActive: <EmojiEventsOutlinedIcon sx={{ fontSize: 28 }} />,
    label: 'tab_achievements',
  },
  tab_leaderboard: {
    route: '/app/topplistan',
    iconActive: <LeaderboardRoundedIcon sx={{ fontSize: 28 }} />,
    iconInActive: <LeaderboardOutlinedIcon sx={{ fontSize: 28 }} />,
    label: 'tab_leaderboard',
  },
  tab_competitions: {
    route: '/app/utmaningar',
    iconActive: <ReorderOutlinedIcon sx={{ fontSize: 28 }} />,
    iconInActive: <ReorderOutlinedIcon sx={{ fontSize: 28 }} />,
    label: 'tab_competitions',
  },
};

export const getNavigationItems = (currentCompetition: any) => {
  const competitionsItemsGroup = [items.tab_leaderboard, items.tab_assignments, items.tab_achievements];

  let itemsToReturn: NavigationItem[] = [];

  if (!currentCompetition) {
    itemsToReturn = [items.tab_competitions];
  } else if (currentCompetition) {
    itemsToReturn = [...competitionsItemsGroup];
  }

  return itemsToReturn;
};
