import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';

import streak1 from '../../../assets/badges/streak1.svg';
import streak2 from '../../../assets/badges/streak2.svg';
import streak3 from '../../../assets/badges/streak3.svg';
import streak4 from '../../../assets/badges/streak4.svg';
import streak5 from '../../../assets/badges/streak5.svg';
import { getCurrentCompetition } from '../../../store/competitionSlice';
import { getCurrentResults } from '../../../store/resultsSlice';

interface AchievementsStreakProps {}

const AchievementsStreak: React.FC<AchievementsStreakProps> = () => {
  const currentCompetition = useSelector(getCurrentCompetition);
  const currentResults = useSelector(getCurrentResults);

  const baseSize = 20;

  const getHighestStreak = (): number => {
    if (!currentCompetition || !currentResults?.assignmentsResults) return 0;

    const streaksArray = Object.values(currentResults.assignmentsResults).map((assignment: any) => {
      const streaks = assignment.points.reduce(
        // eslint-disable-next-line no-sequences
        (res: number[], n: number) => (n ? res[res.length - 1]++ : res.push(0), res),
        [0],
      );
      return Math.max(...streaks);
    });

    return Math.max(...streaksArray);
  };

  const getStreakImage = (days: number) => {
    if (days <= 4) return streak1;
    if (days <= 8) return streak2;
    if (days <= 12) return streak3;
    if (days <= 20) return streak4;
    if (days <= 32) return streak5;
    return streak5;
  };

  const maxSize = 100;
  const imageSize = Math.min(baseSize + getHighestStreak() * 3, maxSize);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', gap: 2 }}>
      <img
        style={{ width: `${imageSize}px`, height: `${imageSize}px` }}
        alt=""
        src={getStreakImage(getHighestStreak())}
      />

      <Typography variant="body1" color="primary">
        {t('label_longest_streak')} <span style={{ fontWeight: 700 }}>{getHighestStreak()}</span>
      </Typography>
    </Box>
  );
};

export default AchievementsStreak;
