import { Box } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import DialogBadge from '../../../components/dialogs/DialogBadge';
import { getCurrentCompetition } from '../../../store/competitionSlice';
import { getCurrentResults } from '../../../store/resultsSlice';
import { badges } from '../../../utilities/badges';
import BadgeContainer from './BadgeContainer';
import { getPointsOnlyArrays, getSumPointsOnlyArrays, getTotalPoints } from './achievementsMethods';

interface DialogContent {
  description: string;
  img: string;
  alt: string;
}

interface AchievementsBadgesProps {}

const AchievementsBadges: React.FC<AchievementsBadgesProps> = () => {
  const currentCompetition = useSelector(getCurrentCompetition);
  const currentResults = useSelector(getCurrentResults);

  const [displayDialog, setDisplayDialog] = useState<boolean>(false);
  const [dialogContent, setDialogContent] = useState<DialogContent>({
    description: '',
    img: '',
    alt: '',
  });

  const pointsOnlyArrays = getPointsOnlyArrays(currentResults);
  const sumPointsOnlyArrays = getSumPointsOnlyArrays(pointsOnlyArrays);
  const totalPoints = getTotalPoints(sumPointsOnlyArrays);

  const badgeStyling = (condition: number, badgeCondition: number): {} => {
    if (condition < badgeCondition) {
      return {
        opacity: 0.4,
        filter: 'grayscale(80%)',
        width: '33.33%',
        height: '100px',
        position: 'relative',
        '&:hover': {
          cursor: 'pointer',
        },
      };
    } else {
      return {
        opacity: 1,
        filter: 'none',
        width: '33.33%',
        height: '100px',
        position: 'relative',
        '&:hover': {
          cursor: 'pointer',
        },
      };
    }
  };

  return (
    <>
      <DialogBadge
        description={dialogContent.description}
        alt={dialogContent.alt}
        img={dialogContent.img}
        displayDialog={displayDialog}
        setDisplayDialog={setDisplayDialog}
      />
      <BadgeContainer>
        {badges.map((badge, i) => (
          <Box
            data-testid={`badge-result-points-${i}`}
            key={i}
            sx={{ ...badgeStyling(totalPoints, currentCompetition?.badgeConditions[i]) }}
            onClick={() => {
              setDialogContent({
                description: `${currentCompetition?.badgeConditions[i]} ${t(badge.label)}`,
                img: badge.img,
                alt: badge.alt,
              });
              setDisplayDialog(true);
            }}
          >
            <img
              src={badge.img}
              alt={badge.alt}
              style={{
                position: 'absolute',
                padding: '4px',
                zIndex: '1',
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
        ))}
      </BadgeContainer>
    </>
  );
};

export default AchievementsBadges;
