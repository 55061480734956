import {
  DocumentData,
  QuerySnapshot,
  WithFieldValue,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';

import { db } from '../firebaseConfig';

/**
 *
 * fetch all defualt assignments
 *
 */
export const getAssignmentsFirebase = async (): Promise<QuerySnapshot<DocumentData> | undefined> => {
  const assignmentsSnapshot = await getDocs(collection(db, 'assignments'));

  if (!assignmentsSnapshot.empty) {
    return assignmentsSnapshot;
  }
  return undefined;
};

/**
 *
 * fetch active defualt assignments
 *
 */
export const getActiveAssignmentsFirebase = async (): Promise<QuerySnapshot<DocumentData> | undefined> => {
  const queryAciveAssignments = query(collection(db, 'assignments'), where('status', '==', 'active'));

  const assignmentsSnapshot = await getDocs(queryAciveAssignments);

  if (!assignmentsSnapshot.empty) {
    return assignmentsSnapshot;
  }
  return undefined;
};

/**
 *
 *
 *
 *
 */
/**
 *
 * Add a new competition
 *
 */
export const addAssignmentFirebase = async (assignment: WithFieldValue<DocumentData>): Promise<void | undefined> => {
  if (assignment.id) {
    const assignmentRef = doc(db, 'assignments', assignment.id);
    await setDoc(assignmentRef, assignment);

    return;
  }

  return undefined;
};
