import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  List,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { DocumentData } from 'firebase/firestore';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router';

import MainContainer from '../../components/MainContainer';
import TogglyBar from '../../components/TogglyBar';
import TopBar from '../../components/TopBar';
import { getResultsSingleTeamFirebase } from '../../firebase/db/results';
import { getAllTeamScoresFirebase } from '../../firebase/db/teamScores';
import { readCurrentDeviceState, setErrorMsg, setShowSettingsDrawer } from '../../store/appSlice';
import { getCurrentCompetition } from '../../store/competitionSlice';
import { getCurrentResults } from '../../store/resultsSlice';
import { getCurrentUser } from '../../store/userSlice';
import { shortenString } from '../../utilities/functionality/stringHandlers';
import { Results } from '../../utilities/types';
import LeaderboardIndividualItem from './leaderboardScreenComponents/LeaderboardIndividualItem';
import LeaderboardTeamItem from './leaderboardScreenComponents/LeaderboardTeamItem';

interface LeaderboardScore {
  userId?: string;
  score: number;
  nickName?: string;
  nrOfDaysSubmitted?: number;
  isAnonymous?: boolean;
}

type LeaderboardScreenProps = {};
const LeaderboardScreen: React.FC<LeaderboardScreenProps> = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);
  const currentCompetition = useSelector(getCurrentCompetition);
  const currentResults = useSelector(getCurrentResults);
  const isDesktop = useSelector(readCurrentDeviceState);

  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [scores, setScores] = useState<Array<LeaderboardScore & DocumentData>>([]);
  const [teamScores, setTeamScores] = useState<any>();
  const [toggleModeValue, setToggleModeValue] = useState('local');
  const [scoreMode, setScoreMode] = useState<string>('total');

  /**
   *
   *
   * Fetch scores from a specific team
   *
   */
  const fetchResultsFromTeam = useCallback(async () => {
    const querySnapshotTeam = await getResultsSingleTeamFirebase({
      competitionId: currentResults?.competitionId,
      team: currentResults?.team,
    });

    let _results: Results[] = [];

    querySnapshotTeam?.forEach((doc: any) => {
      _results.push(doc.data());
    });

    return _results;
  }, [currentResults?.competitionId, currentResults?.team]);

  /**
   *
   * Fetch scores from all teams
   *
   */
  const fetchAllTeamScores = useCallback(async () => {
    const _teamScores = await getAllTeamScoresFirebase(currentResults?.competitionId);

    const _teamScoresArray = _teamScores
      ? Object.entries(_teamScores).map(([key, data]) => ({
          team: key,
          points: data.score,
          days: data.days,
          average: data.days === 0 ? 0 : parseFloat((data.score / data.days).toFixed(1)),
        }))
      : [];

    return _teamScoresArray;
  }, [currentResults?.competitionId]);

  /**
   *  Lifecycle
   */
  useEffect(() => {
    const init = async () => {
      try {
        const _resultsFromTeam: Results[] = await fetchResultsFromTeam();
        const isTeamCompetition: boolean = _resultsFromTeam[0].team !== null;

        let sortedScores = sortScores(_resultsFromTeam);
        setScores(sortedScores);

        if (isTeamCompetition) {
          const _allTeamScores: any = await fetchAllTeamScores();
          setTeamScores(_allTeamScores);
        }
      } catch (errorMsg) {
        dispatch(setErrorMsg(t('error_text_fetch_leaderboard')));
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      }
    };

    if (currentResults) {
      init();
    }
  }, [currentUser, currentResults, dispatch, fetchResultsFromTeam, fetchAllTeamScores]);

  /**
   *
   * sortTeamsScores
   *
   */
  const sortTeamsScores = (data: any) => {
    if (scoreMode === 'average') {
      return data.sort((a: any, b: any) => (a.average < b.average ? 1 : -1));
    } else {
      return data.sort((a: any, b: any) => (a.points < b.points ? 1 : -1));
    }
  };

  /**
   *
   * sort
   *
   */
  const sortScores = (results: Array<Results & DocumentData>): LeaderboardScore[] | [] => {
    if (!results) return [];
    return results
      .map((result) => ({
        nickName: result.nickName ?? undefined,
        userId: result.userId,
        score: result.publicScore,
        nrOfDaysSubmitted: result.nrOfDaysSubmitted,
        isAnonymous: result.isAnonymous,
      }))
      .sort((a, b) => (a.score < b.score ? 1 : -1));
  };

  /**
   *
   * sum up scores from all teams to display in header
   *
   */
  const sumTeamsScores = (teamScores: any[]): number | null => {
    let _teamScores = 0;

    if (teamScores) {
      teamScores.forEach((team) => {
        _teamScores += team.points;
      });

      return _teamScores;
    } else {
      return null;
    }
  };

  return (
    <>
      {currentCompetition ? (
        <>
          <TopBar
            title={t('title_leaderboard')}
            position="static"
            rightAction={
              !isDesktop && (
                <Box sx={{ marginRight: 2 }}>
                  <IconButton
                    edge="start"
                    data-testid="button-settings"
                    aria-label="settings"
                    onClick={() => {
                      dispatch(setShowSettingsDrawer(true));
                    }}
                    sx={{
                      color: 'text.primary',
                    }}
                  >
                    <MenuRoundedIcon fontSize="medium" />
                  </IconButton>
                </Box>
              )
            }
          />

          <MainContainer>
            {isLoading ? (
              <Box
                sx={{
                  width: '100%',
                  height: '80vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {currentResults?.team !== null && (
                  <TogglyBar
                    selectedValue={toggleModeValue}
                    toggleValues={[
                      {
                        value: 'local',
                        label: shortenString(currentResults?.team, 17),
                      },
                      {
                        value: 'global',
                        label: t('toggle_button_global'),
                      },
                    ]}
                    handleToggleValue={(event) => {
                      setToggleModeValue(event.target.value);
                    }}
                  />
                )}
                {toggleModeValue === 'global' && currentResults?.team && (
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography
                      variant="body2"
                      sx={{ ml: 1, bgcolor: 'background.paper', p: 2.5, px: 4, borderRadius: 2 }}
                      color="primary"
                    >
                      <span>
                        {`${t('pill_all_teams_scores')}:`}{' '}
                        <span style={{ fontWeight: 700 }}>{`${sumTeamsScores(teamScores)}${t(
                          'snackbar_points',
                        )}`}</span>
                      </span>
                    </Typography>
                    <FormControl variant="outlined">
                      <InputLabel id="demo-simple-select-label" variant="standard">
                        {t('label_score_mode')}
                      </InputLabel>

                      <Select
                        labelId="score-mode-label"
                        id="score-mode-select"
                        sx={{ bgcolor: 'background.paper' }}
                        value={scoreMode}
                        onChange={(event) => {
                          setScoreMode(event.target.value);
                        }}
                        inputProps={{ sx: { py: 2 } }}
                      >
                        <MenuItem value={'average'}>{t('label_score_mode_average')}</MenuItem>
                        <MenuItem value={'total'}>{t('label_score_mode_total')}</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                )}

                {/* {toggleModeValue === 'local' && currentResults?.team && (
                  <Typography variant="body2" sx={{ ml: 2 }}>
                    <span style={{ width: '100%', textAlign: 'center' }}>
                      {`${t('pill_team_scores')}:`}{' '}
                      <span style={{ fontWeight: 700 }}>{`${sumTeamScores(scores)}${t('snackbar_points')}`}</span>
                    </span>
                  </Typography>
                )} */}

                <List
                  sx={{
                    p: 0,
                    mb: 24,
                    border: '1px solid',
                    borderColor: 'custom.paperBorder',
                    borderRadius: 3,
                    mt: 2,
                  }}
                >
                  {toggleModeValue === 'local' && (
                    <>
                      {scores &&
                        currentUser &&
                        scores.map((score, i) => (
                          <LeaderboardIndividualItem
                            key={i}
                            length={scores.length}
                            index={i}
                            nickName={score.nickName}
                            userId={score.userId}
                            currentUser={currentUser}
                            nrOfDaysSubmitted={score.nrOfDaysSubmitted}
                            score={score.score}
                            noBorder={i === scores.length - 1}
                            isAnonymous={Boolean(score.isAnonymous)}
                          />
                        ))}
                    </>
                  )}

                  {toggleModeValue === 'global' && (
                    <>
                      {currentResults &&
                        sortTeamsScores(teamScores).map((score: any, i: number) => (
                          <LeaderboardTeamItem
                            key={i}
                            length={teamScores.length}
                            index={i}
                            currentResults={currentResults}
                            nrOfDaysSubmitted={score.days}
                            score={score.points}
                            team={score.team}
                            noBorder={i === teamScores.length - 1}
                            scoreMode={scoreMode}
                            averageScore={score.average}
                          />
                        ))}
                    </>
                  )}
                </List>
              </Box>
            )}
          </MainContainer>
        </>
      ) : (
        <Navigate to="/app/utmaningar" replace />
      )}
    </>
  );
};

export default LeaderboardScreen;
