import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { combineReducers } from 'redux';

import appReducer from './appSlice';
import competitionReducer from './competitionSlice';
import daysReducer from './daysHandlerSlice';
import joinCompetitionReducer from './joinCompetitionSlice';
import resultsReducer from './resultsSlice';
import userReducer from './userSlice';

const rootReducer = combineReducers({
  userSlice: userReducer,
  competitionSlice: competitionReducer,
  joinCompetitionSlice: joinCompetitionReducer,
  resultsSlice: resultsReducer,
  appSlice: appReducer,
  daysSlice: daysReducer,
});

const middleware = [...getDefaultMiddleware()];

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

export type RootState = ReturnType<typeof rootReducer>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
