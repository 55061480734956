import { Box, ListItem, ListItemText, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

import { User } from '../../../utilities/types';

interface LeaderboardIndividualItemProps {
  length: number;
  index: number;
  nickName?: string;
  userId?: string;
  currentUser: User;
  nrOfDaysSubmitted?: number;
  score?: number;
  noBorder: boolean;
  isAnonymous: boolean;
}

const LeaderboardIndividualItem: React.FC<LeaderboardIndividualItemProps> = ({
  length,
  score,
  index,
  nickName,
  userId,
  currentUser,
  nrOfDaysSubmitted,
  noBorder,
  isAnonymous,
}) => {
  // const medals: Record<string, { border: string }> = {
  //   '1': { border: '5px solid #f3d032' },
  //   '2': { border: '5px solid #d9dadc' },
  //   '3': { border: '5px solid #f3ae8b' },
  // };

  const isCurrentUser = userId === currentUser?.uid;

  return (
    <ListItem
      sx={{
        bgcolor: index % 2 === 0 ? 'background.paper' : 'custom.listItem',
        borderBottom: noBorder ? 'none' : '1px solid',
        borderColor: 'custom.paperBorder',
        pl: 3.5,
        pr: 6,
        py: isAnonymous && !isCurrentUser ? 5.5 : 2.5,
        borderRadius: `${index === 0 ? 12 : 0}px ${index === 0 ? 12 : 0}px ${index + 1 === length ? 12 : 0}px ${
          index + 1 === length ? 12 : 0
        }px`,
      }}
    >
      <Box
        sx={{
          borderRadius: 10,
          width: 30,
          height: 30,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mr: 4,
          // ...medals[`${index + 1}`],
        }}
      >
        <Typography variant="body2" color="primary" sx={{ color: 'light.main', fontWeight: 600, lineHeight: 1 }}>{`${
          index + 1
        }`}</Typography>
      </Box>

      <ListItemText
        primary={
          nickName && !isAnonymous ? (
            nickName.charAt(0).toUpperCase() + nickName.slice(1)
          ) : nickName && isCurrentUser ? (
            nickName.charAt(0).toUpperCase() + nickName.slice(1)
          ) : (
            <Box sx={{ width: 100, height: 24, borderRadius: 6, bgcolor: 'custom.paperBorder' }} />
          )
        }
        primaryTypographyProps={{
          sx: { fontWeight: isCurrentUser ? 600 : 400, py: 0, color: 'text.primary' },
        }}
        secondaryTypographyProps={{
          sx: {
            fontSize: '0.7rem',
            fontWeight: isCurrentUser ? 600 : 400,
          },
        }}
        secondary={
          !isAnonymous
            ? `${t('label_submitted_days')}: ${nrOfDaysSubmitted}`
            : isCurrentUser
            ? `${t('label_submitted_days')}: ${nrOfDaysSubmitted}`
            : null
        }
      />

      <Box display="flex" flexDirection="column">
        <Typography
          data-testid={`text-leaderboard-score-${nickName}`}
          component="span"
          variant="body1"
          color="primary"
          sx={{ fontWeight: !isAnonymous ? 600 : 400 }}
        >
          {!isAnonymous ? (
            `${score}p`
          ) : isCurrentUser ? (
            `${score}p`
          ) : (
            <Box sx={{ width: 32, height: 24, borderRadius: 6, bgcolor: 'custom.paperBorder' }} />
          )}
        </Typography>
      </Box>
    </ListItem>
  );
};

export default LeaderboardIndividualItem;
