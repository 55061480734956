import { Theme, createTheme } from '@mui/material/styles';

export const commonStyles = {
  spacing: 4,
  typography: {
    button: {
      textTransform: 'unset' as const,
    },
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        fullWidth: {
          paddingTop: 5,
          paddingBottom: 5,
        },
        contained: {
          fontSize: '1.0rem',
          fontWeight: '500',
          borderRadius: 24,
          paddingLeft: 24,
          paddingRight: 24,
        },
        text: {
          fontSize: '1.0rem',
          fontWeight: '500',
          borderRadius: 24,
          paddingLeft: 24,
          paddingRight: 24,
        },
        outlined: {
          fontSize: '1.0rem',
          fontWeight: '500',
          borderRadius: 24,
          paddingLeft: 24,
          paddingRight: 24,
          borderWidth: '2px',
          '&:hover': {
            borderWidth: '2px',
          },
        },
        sizeSmall: {
          fontSize: '1.0rem',
          fontWeight: '500',
          paddingLeft: 16,
          paddingRight: 16,
          paddingBottom: 0,
          paddingTop: 0,
          height: 34,
        },
        sizeLarge: {
          height: 44,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundImage: 'unset',
        },
      },
    },
    MuiTabScrollButton: {
      styleOverrides: {
        root: {
          color: '#056A54',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundImage: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: '60px',
        },
      },
    },
  },
};

const lightPalette = {
  primary: {
    main: '#010101',
  },
  secondary: {
    main: '#117C54',
  },
  text: {
    primary: '#010101',
    secondary: '#010101',
  },
  background: {
    default: '#F3F4F6',
    paper: '#fff',
    paperHover: '#EEEFF2',
  },
  custom: {
    backdrop: 'rgba(248, 249, 251,0.7)',
    paperBorder: '#D8D8D8',
    inputBorder: '#C4C4C4',
    disabled: 'rgba(0,0,0, 0.3)',
    listItem: '#F6F6F6',
  },
  info: {
    main: '#2E86C1',
  },
  success: {
    main: '#1BA770',
  },
  warning: {
    main: '#FFA93D',
  },
  error: {
    main: '#ED2960',
  },

  grey: {
    50: '#9FB1E3',
    100: '#8BA3D4',
    200: '#7A94C6',
    300: '#6884B8',
    400: '#5475A9',
    500: '#43689D',
    600: '#3D5C8D',
    700: '#35537E',
    800: '#233551',
    900: '#172639',
    A100: '#8BA3D4',
    A200: '#7A94C6',
    A400: '#5475A9',
    A700: '#35537E',
  },
};

const darkPalette = {
  primary: {
    main: '#F1F1E9',
  },
  secondary: {
    main: '#0BE180',
  },
  text: {
    primary: '#ffffff',
    secondary: '#ffffff',
  },
  background: {
    default: '#141414',
    paper: '#2E2E2E',
    paperHover: '#3B3B3B',
  },
  custom: {
    backdrop: 'rgba(18,18,18, 0.7)',
    paperBorder: '#3B3B3B',
    inputBorder: '#3B3B3B',
    disabled: 'rgba(255,255,255, 0.3)',
    listItem: '#272727',
  },
  info: {
    main: '#ffffff',
  },
  success: {
    main: '#0BE180',
  },
  warning: {
    main: '#FFA93D',
  },
  error: {
    main: '#F36868',
  },

  grey: {
    50: '#172639',
    100: '#233551',
    200: '#35537E',
    300: '#3D5C8D',
    400: '#43689D',
    500: '#5475A9',
    600: '#6884B8',
    700: '#7A94C6',
    800: '#8BA3D4',
    900: '#9FB1E3',
    A100: '#2F486E',
    A200: '#35537E',
    A400: '#43689D',
    A700: '#7A94C6',
  },
};

export const lightTheme: Theme = createTheme({
  palette: {
    mode: 'light',
    ...lightPalette,
  },
  ...commonStyles,
});

export const darkTheme: Theme = createTheme({
  palette: {
    mode: 'dark',
    ...darkPalette,
  },
  ...commonStyles,
});
