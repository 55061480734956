export const assignments = [
  {
    id: 'EFo7i0TxRKsBM3K5EK67',
    type: 'regular',
    title: {
      en: 'Eat fruits & berries',
      se: 'Ät frukt & bär',
    },
    index: 1,
    information: {
      en: 'Fruit and vegetables contain vitamins, minerals and other important substances. Many vegetables and fruits are rich in fiber, and fiber have different positive effects on the body. Fiber satiates well and keeps the stomach moving.',
      se: 'Grönsaker, frukt och bär innehåller mycket vitaminer, mineraler och andra nyttigheter. Många grönsaker och frukter är rika på fibrer, som har olika positiva effekter i kroppen. Fibrer mättar bra och håller magen igång.',
    },
    description: {
      se: '2st frukter eller nävar bär',
      en: 'Two fruits or fistful of berries',
    },
    color: '#FF5733',
  },
  {
    id: 'yYDLJLwlAUhQ5Cwfocu2',
    type: 'regular',
    title: {
      en: 'Eat vegetables',
      se: 'Ät grönsaker',
    },
    index: 2,
    color: '#FF8D18',
    description: {
      en: '3 vegetables',
      se: '3st grönsaker',
    },
    information: {
      se: 'Grönsaker, frukt och bär innehåller mycket vitaminer, mineraler och andra nyttigheter. Många grönsaker och frukter är rika på fibrer, som har olika positiva effekter i kroppen. Fibrer mättar bra och håller magen igång.',
      en: 'Vegetables and fruits contain minerals, vitamins and other important substances. Many vegetables and fruits are rich in fiber and have different positive effects on the body. Fiber satiates well and keeps the stomach moving.',
    },
  },
  {
    id: 'UHtIcETQQAsPJdk4UtYR',
    index: 3,
    title: {
      en: 'Eat vegetarian',
      se: 'Ät vegetariskt',
    },
    information: {
      en: 'Let two of your main meals(breakfast, lunch, dinner) be lacto-ovo-vegetarian (vegetables, dairy products and even eggs). There are a lot of health benefits by eating lots of vegetables, fruits, legumes and other foods from the plant kingdom.',
      se: 'Låt 2 av dina huvudmåltiden(frukost, lunch, kvällsmat) vara lakto-ovo-vegetariska (vegetabilier, mjölkprodukter och även ägg). Det finns stora hälsovinster med att äta mycket grönsaker, frukt, baljväxter och annan mat från växtriket. Vegetarianer löper till exempel mindre risk att drabbas av en rad olika sjukdomar, som högt blodtryck, hjärt- och kärlsjukdom och vissa cancerformer.',
    },
    type: 'regular',
    color: '#FFC200',
    description: {
      en: '2 Vegetarian meals',
      se: '2 huvudmåltider Vego',
    },
  },
  {
    id: 'o4AsNfatDHzdbEWMwb9J',
    index: 4,
    information: {
      se: 'Ur hälsosynpunkt spelar det ingen roll om man dricker kranvatten eller förpackat vatten. Men tänk på miljöpåverkan förpackat vatten har. Mängden vatten kan skilja lite beroende på hur stor man är, det viktiga är att man har ett sunt vätskeintag',
      en: 'From a health perspective it does not really matter if you drink tap water och packaged water. But think about the environmental impact packaged water has. The amount can differ a bit depending on how big of a person you are, the main point is that you keep yourself hydrated.',
    },
    color: '#2A7A9B',
    title: {
      se: 'Drick vatten',
      en: 'Drink water',
    },
    description: {
      en: '1 litre water',
      se: '1 liter vatten',
    },
    type: 'regular',
  },
  {
    id: 'ifOO5m3rmUKbU2mdhpAK',
    information: {
      se: 'Varva ner i lugn och ro, reflektera över hur dagen har varit. Du kan exempelvis skriva dagbok, vad uppskattade du med dagen? Vill du att något skall vara annorlunda imorgon, varför tycker du så? Avsluta gärna med lite meditation.',
      en: 'Unwind in peace and quiet, reflect on how the day has been. Maybe write a dairy, what did you appreciate about the day? Do you want something to be different tomorrow, why do you think so? Feel free to finish with a little meditation.',
    },
    index: 5,
    color: '#ECDD52',
    description: {
      en: 'Unwind in peace and quiet',
      se: 'Varva ner och reflektera',
    },
    title: {
      se: 'Reflektera',
      en: 'Reflect',
    },
    type: 'regular',
  },
  {
    id: 'Xb5A79T0xkOEcBbXPW31',
    color: '#00BAAD',
    index: 6,
    information: {
      en: 'Meditation and mindful exercises can be carried out in many different ways, find yours and give yourself that break every day. If you are completely new to this you could start using a mobile app like “headspace” or similar.',
      se: 'Meditation, mindfulness kan utföras på olika sätt, hitta ditt sätt och ge dig själv den pausen varje dag. Är detta något helt nytt för dig så finns det appar som guidar dig, ett bra exempel är appen Headspace.',
    },
    description: {
      se: '5-10 minuter meditation i någon form',
      en: '5-10 minutes of some kind of meditation',
    },
    type: 'regular',
    title: {
      en: 'Meditate',
      se: 'Meditera',
    },
  },
  {
    id: '27q4QETO3EgkgT3PlL8f',
    index: 7,
    information: {
      en: 'The best position is the next position. Try to activate yourself at least once every hour. Stretch the body and walk around a bit.',
      se: 'Den bästa positionen är nästa position. Försök att aktivera dig en gång varje halvtimme. Sträck på kroppen och gå runt om du har möjlighet.',
    },
    type: 'regular',
    title: {
      en: 'Activate yourself',
      se: 'Aktivera dig',
    },
    description: {
      en: 'Change position every hour between 8am-4pm',
      se: 'Byt position varje timme mellan 08-16',
    },
    color: '#3D3D6B',
  },
  {
    id: '9OGVEo7m8ieWscsHhknh',
    title: {
      en: 'Exercise',
      se: 'Motionera',
    },
    color: '#501849',
    type: 'regular',
    index: 8,
    information: {
      se: 'Röra dig på ett sätt som gör att du blir varm. Det behöver inte vara vid ett och samma tillfälle. En kvart två gånger varje dag eller flera fem-minuters-pass fungerar lika bra. Kom ihåg att varje steg räknas. Blanda gärna in lite styrka för att hålla igång musklerna.',
      en: "Move in a way that makes you warm. It doesn't have to be at the same time. A quarter of an hour twice each day or several five-minute sessions work just as well. Remember, every step counts. Feel free to mix in some strength to keep the muscles moving.",
    },
    description: {
      se: '30 minuter om dagen',
      en: '30 minutes daily',
    },
  },
  {
    id: 'QEA2qPHQSb9xxOjsTLIs',
    type: 'regular',
    color: '#900D3E',
    title: {
      se: 'Träna',
      en: 'Work out',
    },
    description: {
      en: '10-15 minutes of cardio or muscle training',
      se: '10-15 minuter flås eller styrka.',
    },
    information: {
      en: 'Move in a way that causes you to get a clear increase in pulse and breathing or lets the muscles work a little harder or intensively. The important thing is that you feel strained and preferably a little sweaty.',
      se: 'Röra dig på ett sätt som gör att du en får tydlig ökning av puls och andning eller låter musklerna jobba lite tyngre eller intensivt. Det viktiga är att du känner dig ansträngd och gärna lite svettig.',
    },
    index: 9,
  },
  {
    id: 'pwGI2clTU1X9x8SB10Xc',
    title: {
      en: 'Spread positivity',
      se: 'Sprid positivitet',
    },
    color: '#FFC200',
    index: 10,
    description: {
      en: 'Praise, help or encourage someone',
      se: 'Beröm, hjälp eller uppmuntra någon',
    },
    information: {
      en: 'Our health and well-being are often influenced by the people in our surroundings. A compliment can turn a bad day into a good one, as well as a good laugh or a helping hand.',
      se: 'Vår hälsa och hur vi mår påverkas ofta av människor i vår omgivning. En komplimang kan vända en dålig dag till en bra, likaså ett gott skratt eller en hjälpande hand.',
    },
    type: 'regular',
  },
  {
    id: 'uzWH5rUlSP2QmtrLgWpF',
    type: 'regular',
    description: {
      en: 'Contact somebody daily',
      se: 'Kontakta någon varje dag',
    },
    title: {
      en: 'Keep in touch',
      se: 'Håll kontakten',
    },
    color: '#57C785',
    information: {
      en: 'It is normal to sometimes feel isolated and lonely, especially if you work from home. Then it can feel better if you call, text or keep in touch in other ways with family and friends.',
      se: 'Det är lätt hänt att man stänger in sig och känner sig isolerad, speciellt om man jobbar hemifrån. Då kan det kännas bättre om man ringer, smsar eller håller kontakten på annat sätt med familj och vänner.',
    },
    index: 11,
  },
  {
    id: 'ibp3mtWrVDqmxHNBYSDy',
    index: 12,
    type: 'regular',
    information: {
      se: 'Har du inte skog i närheten leta upp ett ställe med annat grönt, kan vara en park eller liknande. Det viktiga är att du tar dig ifrån stads stressen och uppskattar vår härliga natur!',
      en: 'If you lack a forest close by, find another green spot, it can be a park or similar. The important thing is that you get away from the urban stress and find time to appreciate the lovely nature.',
    },
    color: '#ADD45C',
    description: {
      en: 'Spend time in nature',
      se: 'Tillbringa tid i naturen',
    },
    title: {
      en: 'Experience nature',
      se: 'Upplev naturen',
    },
  },
  {
    id: 'NFGUJsJ54tSuquNzmFgC',
    title: {
      se: 'Förbättra en färdighet',
      en: 'Improve a skill',
    },
    index: 13,
    color: '#3D3D6B',
    description: {
      se: 'Minst 10min varje dag.',
      en: 'Spend 10 minutes every day improving.',
    },
    information: {
      en: 'Feeling that you are making progress in areas you want to develop can be good for your well-being.',
      se: 'Att känna att man gör progression i områden man vill utvecklas kan göra gott för ditt välbefinnande.',
    },
    type: 'regular',
  },
  {
    id: 'TgZK1eqNvBO4SwVbecd5',
    color: '#00BAAD',
    type: 'regular',
    description: {
      se: 'Duscha eller bada kallt',
      en: 'Cold shower or bath',
    },
    title: {
      se: 'Duscha kallt',
      en: 'Shower cold',
    },
    index: 14,
    information: {
      se: 'Kalla duschar drar igång systemet och kan ha flera positiva effekter. Var dock försiktig och gå inte ut för hårt i början om det är nytt för dig.',
      en: 'Cold showers jumpstarts the system and can have many positive effects. If you wish to try this with no experience before please be careful and start slow. There are many guides on how to do this online.',
    },
  },
  {
    id: 'zI2qH4ujbyMBQ1MklasE',
    information: {
      en: '',
      se: '',
    },
    description: {
      en: 'Your own activity',
      se: 'Din egna aktivitet',
    },
    index: 15,
    title: {
      se: 'Personlig aktivitet',
      en: 'Personal assignment',
    },
    type: 'custom-undefined',
    color: '#5D6D7E',
  },
];
