import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import MainContainer from '../../components/MainContainer';
import TopBar from '../../components/TopBar';
import { getResultsSingleTeamFirebase, updateAssignmentsResultsAndScoreFirebase } from '../../firebase/db/results';
import { updateTeamScoresFirebase } from '../../firebase/db/teamScores';
import { setErrorMsg } from '../../store/appSlice';
import { getCurrentCompetition } from '../../store/competitionSlice';
import { getCurrentDaysState } from '../../store/daysHandlerSlice';
import { getCurrentResults, setAssignmentsResults, setResultsScores } from '../../store/resultsSlice';
import { getCurrentUser } from '../../store/userSlice';
import { AssignmentResult } from '../../utilities/types';
import AssignmentList from './assignmentsScreenComponents/AssignmentList';

type AssignmentsEditScreenProps = {};

const AssignmentsEditScreen: React.FC<AssignmentsEditScreenProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);
  const currentCompetition = useSelector(getCurrentCompetition);
  const currentResults = useSelector(getCurrentResults);
  const currentDaysState = useSelector(getCurrentDaysState);

  /**
   *
   *
   */
  const calcNewScores = (newAssignmentsResults: AssignmentResult[]) => {
    let publicScore: number = 0;
    let privateScore: number = 0;

    newAssignmentsResults.forEach((assignment: any) => {
      assignment.points.slice(0, currentCompetition?.duration).forEach((point: any) => {
        publicScore += point;
      });
    });

    newAssignmentsResults.forEach((assignment: any) => {
      assignment.points.forEach((point: any) => {
        privateScore += point;
      });
    });

    return { publicScore: publicScore, privateScore: privateScore };
  };

  /**
   *
   *
   *
   */
  const calculateTeamScore = (_results: any[]) => {
    let totalScore = 0;
    let totalDays = 0;

    _results.forEach((_result) => {
      totalScore += _result.publicScore;
      totalDays += _result.nrOfDaysSubmitted;
    });

    return { totalScore, totalDays };
  };

  /**
   *
   *
   *
   */
  const onCheckAssignmentChange = async (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (!currentResults) return;

    const currentAssignmentPoints = currentResults?.assignmentsResults[index].points[currentDaysState.activeDayIndex];
    const newAssignmentsResults: AssignmentResult[] = currentResults?.assignmentsResults.map(
      (assignmentResult: AssignmentResult, i: number) => {
        if (i === index) {
          return {
            ...assignmentResult,
            points: assignmentResult.points.map((assignmentPoints: any, j: number) => {
              if (j === currentDaysState.activeDayIndex) {
                return currentAssignmentPoints === 0 ? 1 : 0;
              }
              return assignmentPoints;
            }),
          };
        }
        return assignmentResult;
      },
    );

    dispatch(setAssignmentsResults(newAssignmentsResults));

    const { publicScore, privateScore } = calcNewScores(newAssignmentsResults);
    const nrOfPublicSubmittedDays =
      currentResults?.assignmentsResults[0].points.length - 1 < currentCompetition?.duration
        ? currentResults?.assignmentsResults[0].points.length - 1
        : currentCompetition?.duration;

    try {
      await updateAssignmentsResultsAndScoreFirebase({
        userId: currentUser?.uid || '',
        newAssignmentsResults: newAssignmentsResults,
        privateScore: privateScore,
        publicScore: publicScore,
        nrOfDaysSubmitted: nrOfPublicSubmittedDays,
      });

      if (currentResults?.team) {
        const querySnapshotResults = await getResultsSingleTeamFirebase({
          competitionId: currentResults?.competitionId,
          team: currentResults?.team,
        });

        const _results = querySnapshotResults?.docs.map((doc: { data: () => any }) => doc.data()) || [];
        const { totalScore, totalDays } = calculateTeamScore(_results);

        await updateTeamScoresFirebase({
          competitionId: currentResults.competitionId,
          team: currentResults.team,
          score: totalScore,
          days: totalDays,
        });

        dispatch(setResultsScores({ publicScore, privateScore, nrOfPublicSubmittedDays }));
      }
    } catch (e) {
      dispatch(setErrorMsg(t('error_text_general')));
    }
  };

  return (
    <>
      <TopBar title={t('title_edit_day')} />
      <MainContainer>
        {currentCompetition && currentResults && currentResults?.assignmentsResults && (
          <Box mt={8} mb={24} pt={15}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
              <Button
                data-testid="button-edit-day-done"
                fullWidth
                variant="contained"
                onClick={() => {
                  navigate('/app/aktiviteter', {
                    replace: true,
                  });
                }}
              >
                {t('button_save_done')}
              </Button>
            </Box>

            <AssignmentList
              activeDayIndex={currentDaysState.activeDayIndex}
              assignmentsResults={currentResults.assignmentsResults}
              checkBoxChange={onCheckAssignmentChange}
              isSubmitted={false}
              isEditMode={false}
              isFuture={false}
            />
          </Box>
        )}
      </MainContainer>
    </>
  );
};

export default AssignmentsEditScreen;
