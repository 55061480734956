import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Checkbox, Container, Link, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import * as DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import Loader from '../../components/Loader';
import DialogIntegrityPolicy from '../../components/dialogs/DialogIntegrityPolicy';
import AuthContainer from '../../components/stylish/AuthContainer';
import Logo from '../../components/stylish/Logo';
import { signup } from '../../firebase/auth';

interface IFormInputs {
  email: string;
  password: string;
  privacy: boolean;
}

const Signup: React.FC = () => {
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<IFormInputs>({
    defaultValues: {
      privacy: false,
    },
  });
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [signupError, setSignupError] = useState('');
  const [displayDialog, setDisplayDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<Boolean>(false);

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data.email)) {
      setError('email', {
        type: 'manual',
        message: t('error_invalid_email') || 'Error',
      });
    } else if (data.password.length < 12) {
      setError('password', {
        type: 'manual',
        message: t('error_weak_password') || 'Error',
      });
    } else if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$*-=_+.?]{12,}$/.test(data.password)) {
      setError('password', {
        type: 'manual',
        message: t('error_characters_password') || 'Error',
      });
    } else if (!data.privacy) {
      setError('privacy', {
        type: 'manual',
        message: t('error_privacy') || 'Error',
      });
    } else {
      setIsLoading(true);
      try {
        await signup(DOMPurify.sanitize(data.email.trim()), DOMPurify.sanitize(data.password.trim()));
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      } catch (error) {
        if (error instanceof Object && 'message' in error) {
          const signupError = t('error_signup_fail');
          setSignupError(signupError);
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        } else {
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
          throw error;
        }
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      {isLoading && <Loader />}

      <DialogIntegrityPolicy displayDialog={displayDialog} setDisplayDialog={setDisplayDialog} />

      <Container
        data-testid="main-container"
        component="main"
        maxWidth="sm"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
        style={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <Logo />

        <AuthContainer>
          <Box>
            {signupError && (
              <Box sx={{ width: '100%', textAlign: 'center', mb: 2 }}>
                <Typography color="error" variant="body2">
                  {signupError}
                </Typography>
              </Box>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography
                color="primary"
                data-testid="label-signup"
                variant="h5"
                sx={{ fontWeight: 600, mt: 2, mb: 2 }}
              >
                {t('label_signup')}
              </Typography>
              <FormControl variant="outlined" fullWidth sx={{ mt: 1, mb: 2 }}>
                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name } }) => (
                    <>
                      <Typography
                        variant="body1"
                        color="primary"
                        component="label"
                        htmlFor={name}
                        sx={{ fontSize: '1rem', marginBottom: 1 }}
                      >
                        {t('textfield_label_email')}
                      </Typography>

                      <OutlinedInput
                        data-testid="input-email-signup"
                        sx={{ bgcolor: 'background.default' }}
                        value={value}
                        onChange={onChange}
                        name={name}
                        type="text"
                        required={true}
                        id={name}
                        autoComplete="off"
                        error={!!errors.email}
                      />
                      {errors.email && typeof errors.email.message === 'string' && (
                        <FormHelperText sx={{ ml: 0 }} error={true}>
                          {errors.email.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </FormControl>

              <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                <Controller
                  control={control}
                  name="password"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name } }) => (
                    <>
                      <Typography
                        variant="body1"
                        color="primary"
                        component="label"
                        htmlFor="password"
                        sx={{ fontSize: '1rem', marginBottom: 1 }}
                      >
                        {t('textfield_label_password')}
                      </Typography>

                      <OutlinedInput
                        data-testid="input-password-signup"
                        sx={{ bgcolor: 'background.default' }}
                        value={value}
                        onChange={onChange}
                        name={name}
                        type={showPassword ? 'text' : 'password'}
                        required={true}
                        id={name}
                        autoComplete="off"
                        error={!!errors.password}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {errors.password && typeof errors.password.message === 'string' && (
                        <FormHelperText sx={{ ml: 0 }} error={true}>
                          {errors.password.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </FormControl>

              <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Controller
                    name="privacy"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        data-testid="privacy-checkbox"
                        {...field}
                        inputProps={{ 'aria-label': 'Privacy checkbox' }}
                      />
                    )}
                  />

                  <Typography color="primary" variant="body2" sx={{ fontSize: '12px', fontWeight: 700 }}>
                    {t('label_i_accept')}
                    <Link
                      underline="none"
                      onClick={() => {
                        setDisplayDialog(true);
                      }}
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                      color="secondary.main"
                    >
                      {t('link_privacy_policy')}
                    </Link>
                    .
                  </Typography>
                </Box>

                {errors.privacy?.message && (
                  <Typography color="error" variant="caption">
                    {errors.privacy.message}
                  </Typography>
                )}
              </Box>

              <Button
                data-testid="button-signup"
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                sx={{ mt: 1 }}
              >
                {t('button_signup')}
              </Button>
            </form>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={2}>
            <Link
              component={RouterLink}
              to="/app/login"
              variant="button"
              underline="none"
              color="secondary.main"
              sx={{ fontSize: '1rem', fontWeight: 700 }}
            >
              {t('link_already_have_account')}
            </Link>
          </Box>
        </AuthContainer>
      </Container>
    </>
  );
};

export default Signup;
