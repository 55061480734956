import { Box, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import * as React from 'react';

import error from '../assets/illustrations/error.svg';

const GeneralError: React.FC = () => {
  return (
    <Backdrop
      sx={{
        zIndex: '9999',
        bgcolor: 'custom.backdrop',
      }}
      open={true}
    >
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          p: 8,
          gap: 4,
        }}
      >
        <img
          src={error}
          alt="Hälsokuppen logotype looking like a trophy made of broccoli"
          width="260"
          height="260"
          style={{ marginTop: '-80px' }}
        />
        <Typography color="primary" variant="h6" component="h1" textAlign="center">
          Något gick snett, vänligen starta om appen eller ladda om sidan.
        </Typography>
      </Box>
    </Backdrop>
  );
};

export default GeneralError;
