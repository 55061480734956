import ListItem from '@mui/material/ListItem';
import React from 'react';

interface ListCardProps {
  children: React.ReactNode;
  styles?: { [key: string]: any };
  [key: string]: any;
  isFirst?: boolean;
  isLast?: boolean;
  hoverEffect?: boolean;
}

const ListCardItem: React.FC<ListCardProps> = ({ children, styles, isFirst, isLast, hoverEffect, ...rest }) => {
  const hoverStyles = hoverEffect
    ? {
        '&:hover': {
          cursor: 'pointer',
          bgcolor: 'background.paperHover',
        },
      }
    : {};

  return (
    <ListItem
      sx={{
        borderTopLeftRadius: isFirst ? 8 : 0,
        borderBottomLeftRadius: isLast ? 8 : 0,
        borderTopRightRadius: isFirst ? 8 : 0,
        borderBottomRightRadius: isLast ? 8 : 0,
        borderLeft: '1px solid',
        borderRight: '1px solid',
        borderBottom: '1px solid',
        borderTop: isFirst ? '1px solid' : '0px solid',
        borderColor: 'custom.paperBorder',
        bgcolor: 'background.paper',
        ...styles,
        ...hoverStyles,
      }}
      {...rest}
    >
      {children}
    </ListItem>
  );
};

export default ListCardItem;
