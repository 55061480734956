// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, OutlinedInput, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Loader from '../../../components/Loader';
import MainContainer from '../../../components/MainContainer';
import TopBar from '../../../components/TopBar';
import DialogOnboarding from '../../../components/dialogs/DialogOnboarding';
import {
  getCompetitionFirebase,
  updateCompetitionNrOfParticipantsFirebase,
  updateCompetitionTeamsFirebase,
} from '../../../firebase/db/competitions';
import { setUserResultsFirebase } from '../../../firebase/db/results';
import { updateTeamScoresFirebase } from '../../../firebase/db/teamScores';
import { readCurrentDeviceState, readCurrentIPhoneState, setErrorMsg } from '../../../store/appSlice';
import { setCompetition } from '../../../store/competitionSlice';
import { getCurrentJoinCompetitionData } from '../../../store/joinCompetitionSlice';
import { setResults } from '../../../store/resultsSlice';
import { getCurrentUser } from '../../../store/userSlice';
import { resultBadges } from '../../../utilities/badges';
import { Results } from '../../../utilities/types';

type Step4NicknameScreenProps = {};

const Step4NicknameScreen: React.FC<Step4NicknameScreenProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nickName: '',
      isAnonymous: false,
    },
  });

  const currentUser = useSelector(getCurrentUser);
  const currentJoinCompetitionData = useSelector(getCurrentJoinCompetitionData);

  const isDesktop = useSelector(readCurrentDeviceState);
  const isIPhone = useSelector(readCurrentIPhoneState);

  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [displayOnboardingDialog, setDisplayOnboardingDialog] = useState<boolean>(false);

  /**
   *  Lifecycle
   */
  useEffect(() => {
    if (!currentJoinCompetitionData) {
      return navigate('/app/utmaningar');
    }
  }, [currentJoinCompetitionData, navigate, setValue]);

  /**
   *
   * Set results in the database
   * Update the competition with the new number of participants and update the user score
   * Uptade the competition with the new team if assigned
   * Set score in the database
   * Set competition and results to Redux
   */
  const saveToDataBase = async (data: FieldValues) => {
    try {
      if (!currentUser?.uid) return;

      const updatedAssignmentsResults = currentJoinCompetitionData?.assignmentsResults?.map((result: any) => {
        if (currentJoinCompetitionData.allowCustomAssignment && result.type === 'custom-undefined') {
          if (currentJoinCompetitionData?.customAssignment?.title) {
            return {
              ...result,
              title: {
                se: currentJoinCompetitionData?.customAssignment.title,
                en: currentJoinCompetitionData?.customAssignment.title,
              },
              displayAssignment: true,
              type: 'custom-defined',
            };
          } else {
            return { ...result, displayAssignment: true };
          }
        }
        return result;
      });

      const competition = await getCompetitionFirebase(currentJoinCompetitionData?.competitionId);

      const initialResults: Results = {
        userId: currentUser.uid,
        competitionId: currentJoinCompetitionData?.competitionId,
        assignmentsResults: updatedAssignmentsResults || [],
        nickName: DOMPurify.sanitize(data.nickName),
        team: currentJoinCompetitionData?.allowTeams ? currentJoinCompetitionData?.team : null,
        isAnonymous: data.isAnonymous,
        goal: currentJoinCompetitionData?.goal,
        badges: resultBadges,
        status: 'ongoing',
        privateScore: 0,
        publicScore: 0,
        nrOfDaysSubmitted: 0,
      };

      /**
       * Save new data to firebase
       */
      await setUserResultsFirebase({ userId: currentUser?.uid, results: initialResults });
      await updateCompetitionNrOfParticipantsFirebase({
        competitionId: currentJoinCompetitionData?.competitionId,
        nrOfParticipants: competition?.nrOfParticipants + 1,
      });

      /**
       * Conditional saves and redux updates
       */
      if (currentJoinCompetitionData?.allowTeams && currentJoinCompetitionData?.newTeamAdded) {
        await updateCompetitionTeamsFirebase({
          competitionId: currentJoinCompetitionData?.competitionId,
          teams: [...currentJoinCompetitionData?.teams, currentJoinCompetitionData?.team],
        });

        await updateTeamScoresFirebase({
          competitionId: currentJoinCompetitionData?.competitionId,
          team: currentJoinCompetitionData?.team,
          days: 0,
          score: 0,
        });
      }

      setDisplayOnboardingDialog(true);

      /**
       * update redux store with new data
       */
      dispatch(
        setCompetition({
          currentCompetition: {
            startDate: currentJoinCompetitionData?.startDate,
            duration: currentJoinCompetitionData?.duration,
            ownerId: currentJoinCompetitionData?.ownerId,
            competitionName: currentJoinCompetitionData?.competitionName,
            assignmentsDetails: currentJoinCompetitionData?.assignmentsDetails,
            passCode: currentJoinCompetitionData?.passCode,
            teams: currentJoinCompetitionData?.teams,
            allowCustomAssignment: currentJoinCompetitionData?.allowCustomAssignment,
            competitionId: currentJoinCompetitionData?.competitionId,
            nrOfParticipants: currentJoinCompetitionData?.nrOfParticipants,
            maxNrOfParticipants: currentJoinCompetitionData?.maxNrOfParticipants,
            registrationOpen: currentJoinCompetitionData?.registrationOpen,
            allowTeams: currentJoinCompetitionData?.allowTeams,
            badgeConditions: currentJoinCompetitionData?.badgeConditions,
          },
        }),
      );
      dispatch(setResults({ currentResults: initialResults }));
    } catch (error) {
      dispatch(setErrorMsg(t('error_text_join_competition')));
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  };

  /**
   *
   *
   * Validates and submits the form
   *
   */
  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (data.nickName.length < 2) {
      setError('nickName', {
        type: 'manual',
        message: t('error_nickName_too_short') || 'Error',
      });
    } else {
      setIsLoading(true);
      await saveToDataBase(data);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {displayOnboardingDialog ? (
            <DialogOnboarding
              fullScreen={true}
              competitionName={currentJoinCompetitionData?.competitionName}
              displayDialog={displayOnboardingDialog}
              setDisplayDialog={setDisplayOnboardingDialog}
              allowCustomAssignment={currentJoinCompetitionData?.allowCustomAssignment}
              callback={() => {
                navigate('/app/aktiviteter');
              }}
            />
          ) : (
            <>
              <TopBar position="fixed" hasBackButton />

              <MainContainer contentMaxWidth={600}>
                <Box sx={{ mt: 22, mb: 14 }}>
                  <Typography color="primary" variant="h6" component="p" mt={4} mb={6} fontWeight={600}>
                    <span style={{ fontSize: '1.1rem', fontWeight: 400 }}>{t('label_step_last')}</span>
                    <br /> {t('heading_choose_nickname')}
                  </Typography>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl variant="outlined" fullWidth>
                      <Controller
                        control={control}
                        name="nickName"
                        defaultValue=""
                        render={({ field: { onChange, value, name } }) => (
                          <>
                            <Typography
                              variant="body1"
                              color="primary"
                              component="label"
                              htmlFor={name}
                              sx={{ fontSize: '1rem', marginBottom: 1 }}
                            >
                              {t('textfield_label_nickname')}
                            </Typography>

                            <OutlinedInput
                              data-testid="input-join-competition-nickName"
                              sx={{ bgcolor: 'background.paper' }}
                              value={value}
                              onChange={onChange}
                              name={name}
                              type="text"
                              id={name}
                              autoComplete="off"
                              error={!!errors.nickName}
                            />
                            {errors.nickName && typeof errors.nickName.message === 'string' && (
                              <FormHelperText sx={{ ml: 0 }} error={true}>
                                {errors.nickName.message}
                              </FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </FormControl>

                    <Controller
                      name="isAnonymous"
                      control={control}
                      render={({ field }) => (
                        <Box sx={{ mt: 8, ml: 1 }}>
                          <FormControlLabel
                            label={t('label_be_anonymous')}
                            labelPlacement="end"
                            sx={{ '& .MuiFormControlLabel-root': { ml: 0 } }}
                            componentsProps={{
                              typography: { variant: 'body2', sx: { width: '100%' }, color: 'text.primary' },
                            }}
                            control={
                              <Checkbox
                                data-testid="checkbox-join-challenge-anonymous"
                                {...field}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 36, pr: 0 } }}
                              />
                            }
                          />
                        </Box>
                      )}
                    />

                    {!isDesktop ? (
                      <Box
                        sx={{
                          width: '100%',
                          p: 4,
                          pt: 0,
                          pb: isIPhone ? 10 : 8,
                          position: 'fixed',
                          borderTop: '1px solid',
                          borderColor: 'custom.paperBorder',
                          bottom: 0,
                          left: 0,
                          bgcolor: 'background.default',
                        }}
                      >
                        <Button
                          data-testid="button-join-competition"
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="large"
                          sx={{ mt: 4 }}
                        >
                          {t('button_join_competition')}
                        </Button>
                      </Box>
                    ) : (
                      <Button
                        data-testid="button-join-competition"
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ mt: 8 }}
                      >
                        {t('button_join_competition')}
                      </Button>
                    )}
                  </form>
                </Box>
              </MainContainer>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Step4NicknameScreen;
