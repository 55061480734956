import { Box, Typography } from '@mui/material';
import { useWindowWidth } from '@react-hook/window-size';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import onboardingCustom from '../../../assets/onboarding/onboardingCustom.svg';
import onboardingCustomDark from '../../../assets/onboarding/onboardingCustomDark.svg';
import MainContainer from '../../../components/MainContainer';
import TopBar from '../../../components/TopBar';
import { readCurrentDarkModeState, readCurrentDeviceState } from '../../../store/appSlice';
import { getCurrentJoinCompetitionData } from '../../../store/joinCompetitionSlice';
import JoinCompetitionButtons from './JoinCompetitionButtons';

type Step2CustomInfoScreenProps = {};

const Step2CustomInfoScreen: React.FC<Step2CustomInfoScreenProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();
  const isDesktop = useSelector(readCurrentDeviceState);
  const isDarkMode = useSelector(readCurrentDarkModeState);

  const currentJoinCompetitionData = useSelector(getCurrentJoinCompetitionData);

  useEffect(() => {
    if (!currentJoinCompetitionData) {
      return navigate('/app/utmaningar');
    }
  }, [currentJoinCompetitionData, navigate]);

  /**
   * Save title for user custom assignment
   *
   */
  async function proceedToNextStep() {
    navigate('/app/utmaning-egen-aktivitet');
  }

  return (
    <>
      <TopBar position="static" hasBackButton />

      <MainContainer contentMaxWidth={600}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', p: 2, gap: 4 }}>
          <img
            src={isDarkMode ? onboardingCustomDark : onboardingCustom}
            alt=""
            width={windowWidth / 1.5}
            height={windowWidth / 1.5}
            style={{
              width: '100%',
              maxWidth: 360,
              maxHeight: 360,
            }}
          />

          <Typography color="primary" variant="h5" component="h1" mt={4} fontWeight={600}>
            <span style={{ fontSize: '1.2rem', fontWeight: 400 }}>{t('label_step_2')}</span>
            <br /> {t('heading_custom_assignment_info')}
          </Typography>

          <Typography color="primary" variant="body1" mt={2}>
            {t('text_custom_assignment_join_competition_info_1')}
            <br />
            <br />
            {t('text_custom_assignment_join_competition_info_2')}
          </Typography>

          <JoinCompetitionButtons
            customContinueText="button_continue"
            proceedToNextStep={proceedToNextStep}
            isDesktop={isDesktop}
            showSkipButton={false}
          />
        </Box>
      </MainContainer>
    </>
  );
};

export default Step2CustomInfoScreen;
