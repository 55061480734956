import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, IconButton, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { doc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import DialogAssignment from '../../../components/dialogs/DialogAssignment';
import DialogCustomAssignment from '../../../components/dialogs/DialogCustomAssignment';
import ListCard from '../../../components/stylish/ListCard';
import { db } from '../../../firebase/firebaseConfig';
import { setErrorMsg } from '../../../store/appSlice';
import { setAssignmentsResults } from '../../../store/resultsSlice';
import { getCurrentUser } from '../../../store/userSlice';
import { shortenString } from '../../../utilities/functionality/stringHandlers';
import { AssignmentResult } from '../../../utilities/types';
import AssignmentStreak from './AssignmentStreak';

export interface AssignmentListProps {
  checkBoxChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  displayAssignmentChange?: (index: number | undefined) => void;
  assignmentsResults: [AssignmentResult];
  activeDayIndex: number;
  isSubmitted: boolean;
  isEditMode: boolean;
  isFuture: boolean;
}

const AssignmentList: React.FC<AssignmentListProps> = ({
  checkBoxChange,
  assignmentsResults,
  activeDayIndex,
  isSubmitted,
  isFuture,
  isEditMode,
  displayAssignmentChange,
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const isSmallMobile = useMediaQuery('(max-width:405px)');
  const [displayCustomAssignmentDialog, setDisplayCustomAssignmentDialog] = useState<boolean>(false);
  const currentUser = useSelector(getCurrentUser);

  const [displayDialog, setDisplayDialog] = useState<boolean>(false);
  const [selectedAssignment, setSelectedAssignment] = useState<{
    assignmentResult: AssignmentResult;
    dbIndex: number;
  } | null>(null);

  const lang = i18n.language === 'en' ? 'en' : 'se';

  const showDialog = (assignmentResult: AssignmentResult, i: number) => {
    setSelectedAssignment({ assignmentResult: assignmentResult, dbIndex: i });
    setDisplayDialog(true);
  };

  const showCustomAssignmentDialog = (assignmentResult: AssignmentResult, i: number) => {
    setSelectedAssignment({ assignmentResult: assignmentResult, dbIndex: i });
    setDisplayCustomAssignmentDialog(true);
  };

  const commonListCardStyles = {
    px: 5,
    py: 2,
    '&:hover': {
      cursor: 'pointer',
    },
  };

  const saveCustomAssignment = async (title: string) => {
    if (!currentUser?.uid) {
      throw new Error('User ID is undefined');
    }
    const docRef = doc(db, 'results', currentUser.uid);

    try {
      const customAssignmentIndex = assignmentsResults.findIndex(
        (assignment) => assignment.type === 'custom-undefined',
      );

      if (customAssignmentIndex !== -1) {
        const updatedAssignmentsResults = assignmentsResults.map((assignment, index) => {
          if (index === customAssignmentIndex) {
            return {
              ...assignment,
              title: {
                ...assignment.title,
                se: title,
                en: title,
              },
              type: 'custom-defined',
            };
          }
          return assignment;
        });

        // Update the Firestore document
        await updateDoc(docRef, {
          assignmentsResults: updatedAssignmentsResults,
        });

        dispatch(setAssignmentsResults(updatedAssignmentsResults));
      }
    } catch (error) {}
  };

  /**
   *
   *
   *
   */
  return (
    <>
      <DialogAssignment
        selectedAssignment={selectedAssignment}
        displayDialog={displayDialog}
        setDisplayDialog={setDisplayDialog}
        displayAssignmentChange={displayAssignmentChange}
      />

      <DialogCustomAssignment
        displayDialog={displayCustomAssignmentDialog}
        setDisplayDialog={setDisplayCustomAssignmentDialog}
        callback={saveCustomAssignment}
      />

      <List sx={{ display: 'flex', flexDirection: 'column' }}>
        {assignmentsResults &&
          assignmentsResults.map((assignment, i) => {
            if (!assignment.displayAssignment) return null;

            let title = assignment?.title[lang];
            let description = assignment?.description[lang];

            return (
              <ListCard
                styles={
                  assignmentsResults[i].points[activeDayIndex] === 1
                    ? {
                        ...commonListCardStyles,
                        borderColor: 'secondary.main',
                      }
                    : {
                        ...commonListCardStyles,
                      }
                }
                key={assignment.id}
                onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (!isFuture && assignment.type !== 'custom-undefined') {
                    checkBoxChange(event, i);
                  } else if (isFuture && assignment.type !== 'custom-undefined') {
                    dispatch(setErrorMsg(t('error_text_too_soon')));
                  }
                }}
              >
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        sx={{
                          width: '4px',
                          height: '52px',
                          borderRadius: '4px',
                          bgcolor:
                            assignmentsResults[i].points[activeDayIndex] === 1 ? 'secondary.main' : assignment?.color,
                          opacity: isFuture ? '.1' : '.8',
                          ml: -3,
                          mr: 3,
                          mt: -2,
                          mb: -2,
                        }}
                      />
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography
                            variant="body1"
                            color="primary"
                            sx={{
                              fontWeight: 500,
                              padding: 2,
                              paddingTop: 1,
                              paddingBottom: 1,
                              borderRadius: 2,
                              '&:hover': {
                                cursor: 'pointer',
                                bgcolor: 'background.default',
                              },
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              if (assignment.type === 'custom-undefined') {
                                showCustomAssignmentDialog(assignment, i);
                              } else {
                                showDialog(assignment, i);
                              }
                            }}
                          >
                            {title}
                          </Typography>
                          {
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();

                                showDialog(assignment, i);
                              }}
                              sx={{
                                ml: -2,
                              }}
                              size="medium"
                              disabled={isEditMode}
                            >
                              <InfoOutlinedIcon fontSize="small" />
                            </IconButton>
                          }
                        </Box>
                        <Typography
                          variant="caption"
                          sx={{
                            paddingLeft: 2,
                            mt: -2,
                            fontWeight: 400,
                          }}
                          color="primary"
                        >
                          {isSmallMobile ? shortenString(description as string, 34) : description}
                        </Typography>
                      </Box>
                    </Box>
                  }
                />

                {!isSubmitted && (
                  <AssignmentStreak
                    assignmentResult={assignmentsResults[i]}
                    assignmentPoint={assignmentsResults[i].points[activeDayIndex]}
                  />
                )}
                {assignment.type === 'custom-undefined' ? (
                  <Button
                    data-testid="button-add-custom-assignment"
                    onClick={() => {
                      showCustomAssignmentDialog(assignment, i);
                    }}
                    variant="outlined"
                    name={`assignment`}
                    color="primary"
                  >
                    {t('button_add_assignment')}
                  </Button>
                ) : (
                  <Checkbox
                    data-testid={`checkbox-assignment-${
                      assignmentsResults.find((resultDetail) => assignment.id === resultDetail?.id)?.index
                    }`}
                    disabled={isFuture || isEditMode}
                    name={`assignment ${i}`}
                    edge="end"
                    color="secondary"
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                    checked={assignmentsResults[i].points[activeDayIndex] === 0 ? false : true}
                    inputProps={{ 'aria-labelledby': 'label' }}
                  />
                )}
              </ListCard>
            );
          })}
      </List>
    </>
  );
};

export default AssignmentList;
