import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

type Props = {
  displayDialog: boolean;
  setDisplayDialog: Dispatch<SetStateAction<boolean>>;
  title: string;
  description: string;
  img: string;
  alt: string;
};

const DialogNewBadge = ({ title, description, img, alt, displayDialog, setDisplayDialog }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Dialog
      open={Boolean(displayDialog)}
      onClose={() => {
        setDisplayDialog(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setDisplayDialog(false);
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box display="flex" flexDirection="column" p={8} textAlign="center" gap={2}>
          <img src={img} alt={alt} width="100%" />
          <Typography component="h2" variant="h6" data-testid="new-badge-title">
            {title}
          </Typography>

          <Typography component="p" variant="body1">
            {description}
          </Typography>
        </Box>

        <Button
          data-testid="button-to-achievements"
          variant="outlined"
          fullWidth
          size="large"
          sx={{
            mt: 2,
            mb: 2,
          }}
          onClick={() => {
            setDisplayDialog(false);
            navigate('/app/prestationer');
          }}
          endIcon={<ArrowForwardRoundedIcon />}
        >
          {t('button_to_achievements')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default DialogNewBadge;
