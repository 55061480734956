import { Box, Container, FormControl, FormHelperText, OutlinedInput, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import * as DOMPurify from 'dompurify';
import { sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import DialogSimple from '../../components/dialogs/DialogSimple';
import AuthContainer from '../../components/stylish/AuthContainer';
import Logo from '../../components/stylish/Logo';
import { auth } from '../../firebase/firebaseConfig';

const ResetPassword: React.FC = () => {
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();

  const [resetError, setResetError] = useState<any>('');
  const [displayDialog, setDisplayDialog] = useState<boolean>(false);

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data.email)) {
      setError('email', {
        type: 'manual',
        message: t('error_invalid_email') || 'Error',
      });
    } else {
      try {
        await sendPasswordResetEmail(auth, DOMPurify.sanitize(data.email.trim()));
        setDisplayDialog(true);
        setResetError('');
      } catch (error) {
        if (error instanceof Object && 'message' in error) {
          setResetError(t('error_reset_password'));
        } else {
          throw error;
        }
      }
    }
  };

  return (
    <>
      <DialogSimple
        displayDialog={displayDialog}
        setDisplayDialog={setDisplayDialog}
        title={t('dialog_title_reset_password_success')}
        content={t('dialog_content_reset_password_success')}
      />
      <Container
        data-testid="main-container"
        component="main"
        maxWidth="sm"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
        style={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <Logo />

        <AuthContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl variant="outlined" fullWidth sx={{ mt: 3, mb: 4 }}>
              <Controller
                control={control}
                name="email"
                defaultValue=""
                rules={{ required: true }}
                render={({ field: { onChange, value, name } }) => (
                  <>
                    <Typography
                      variant="body1"
                      color="primary"
                      component="label"
                      htmlFor={name}
                      sx={{ fontSize: '1rem', marginBottom: 1 }}
                    >
                      {t('textfield_label_reset_password')}
                    </Typography>

                    <OutlinedInput
                      sx={{ bgcolor: 'background.default' }}
                      value={value}
                      onChange={onChange}
                      name={name}
                      type="text"
                      required={true}
                      id={name}
                      autoComplete="off"
                      error={!!errors.email}
                    />
                    {errors.email && typeof errors.email.message === 'string' && (
                      <FormHelperText sx={{ ml: 0 }} error={true}>
                        {errors.email.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </FormControl>

            {resetError && (
              <Box width="100%" textAlign="center">
                <Typography color="error" variant="caption">
                  {resetError}
                </Typography>
              </Box>
            )}

            <Button
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              sx={{
                mt: 1,
                mb: 2,
              }}
            >
              {t('button_send_reset_link')}
            </Button>

            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={2}>
              <Link
                component={RouterLink}
                to="/app/login"
                variant="button"
                underline="none"
                color="secondary"
                sx={{ fontWeight: 600 }}
              >
                {t('link_back_to_login')}
              </Link>
            </Box>
          </form>
        </AuthContainer>
      </Container>
    </>
  );
};

export default ResetPassword;
