import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { Route, Routes } from 'react-router-dom';

import AdminOwnerScreen from '../screens/admin/AdminOwnerScreen';
import AdminScreen from '../screens/admin/AdminScreen';
import CompetitionAdminCreateScreen from '../screens/admin/CompetitionAdminCreateScreen';
import AchievementsScreen from '../screens/private/AchievementsScreen';
import AssignmentEditScreen from '../screens/private/AssignmentEditScreen';
import AssignmentsScreen from '../screens/private/AssignmentsScreen';
import CompetitionsScreen from '../screens/private/CompetitionsScreen';
import LeaderboardScreen from '../screens/private/LeaderboardScreen';
import Step0PassCodeScreen from '../screens/private/joinCompetitionScreens/Step0PassCodeScreen';
import Step0WelcomeScreen from '../screens/private/joinCompetitionScreens/Step0WelcomeScreen';
import Step1ChooseAssignmentsInfoScreen from '../screens/private/joinCompetitionScreens/Step1ChooseAssignmentsInfoScreen';
import Step1ChooseAssignmentsScreen from '../screens/private/joinCompetitionScreens/Step1ChooseAssignmentsScreen';
import Step2CustomAssignmentsScreen from '../screens/private/joinCompetitionScreens/Step2CustomAssignmentsScreen';
import Step2CustomInfoScreen from '../screens/private/joinCompetitionScreens/Step2CustomInfoScreen';
import Step3JoinTeamScreen from '../screens/private/joinCompetitionScreens/Step3JoinTeamScreen';
import Step3TeamInfoScreen from '../screens/private/joinCompetitionScreens/Step3TeamInfoScreen';
import Step4NicknameScreen from '../screens/private/joinCompetitionScreens/Step4NicknameScreen';
import AboutScreen from '../screens/public/Aboutscreen';
import HomeScreen from '../screens/public/HomeScreen';
import ResetPasswordScreen from '../screens/public/ResetPasswordScreen';
import SigninScreen from '../screens/public/SigninScreen';
import SignupScreen from '../screens/public/SignupScreen';
import { getCurrentUser } from '../store/userSlice';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

interface AppRoutesProps {}

const AppRoutes: React.FC<AppRoutesProps> = () => {
  const currentUser = useSelector(getCurrentUser);
  const authenticated = Boolean(currentUser?.uid);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/app" replace />} />
      <Route path="*" element={<Navigate to="/app" replace />} />

      <Route path="/app/home" element={<HomeScreen />} />

      <Route path="/app/about" element={<AboutScreen authenticated={authenticated} />} />
      <Route path="/app/info" element={<AboutScreen authenticated={authenticated} />} />

      <Route
        path="/app"
        element={
          <PublicRoute authenticated={authenticated}>
            <SigninScreen />
          </PublicRoute>
        }
      />
      <Route
        path="/app/login"
        element={
          <PublicRoute authenticated={authenticated}>
            <SigninScreen />
          </PublicRoute>
        }
      />
      <Route
        path="/app/registrera"
        element={
          <PublicRoute authenticated={authenticated}>
            <SignupScreen />
          </PublicRoute>
        }
      />
      <Route
        path="/app/aterstall-losenord"
        element={
          <PublicRoute authenticated={authenticated}>
            <ResetPasswordScreen />
          </PublicRoute>
        }
      />
      <Route
        path="/app/aktiviteter"
        element={
          <PrivateRoute authenticated={authenticated}>
            <AssignmentsScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/redigera-aktiviteter"
        element={
          <PrivateRoute authenticated={authenticated}>
            <AssignmentEditScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/skapa-utmaning"
        element={
          <PrivateRoute authenticated={authenticated}>
            <CompetitionAdminCreateScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/utmaningar"
        element={
          <PrivateRoute authenticated={authenticated}>
            <CompetitionsScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/utmaning-losenord"
        element={
          <PrivateRoute authenticated={authenticated}>
            <Step0PassCodeScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/utmaning-smeknamn"
        element={
          <PrivateRoute authenticated={authenticated}>
            <Step4NicknameScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/utmaning-valkommen"
        element={
          <PrivateRoute authenticated={authenticated}>
            <Step0WelcomeScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/utmaning-valj-aktiviteter-info"
        element={
          <PrivateRoute authenticated={authenticated}>
            <Step1ChooseAssignmentsInfoScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/utmaning-valj-aktiviteter"
        element={
          <PrivateRoute authenticated={authenticated}>
            <Step1ChooseAssignmentsScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/utmaning-egen-aktivitet"
        element={
          <PrivateRoute authenticated={authenticated}>
            <Step2CustomAssignmentsScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/utmaning-egen-aktivitet-info"
        element={
          <PrivateRoute authenticated={authenticated}>
            <Step2CustomInfoScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/utmaning-valj-lag"
        element={
          <PrivateRoute authenticated={authenticated}>
            <Step3JoinTeamScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/utmaning-valj-lag-info"
        element={
          <PrivateRoute authenticated={authenticated}>
            <Step3TeamInfoScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/admin"
        element={
          <PrivateRoute authenticated={authenticated}>
            <AdminScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/utmaningsinfo"
        element={
          <PrivateRoute authenticated={authenticated}>
            <AdminOwnerScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/prestationer"
        element={
          <PrivateRoute authenticated={authenticated}>
            <AchievementsScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/topplistan"
        element={
          <PrivateRoute authenticated={authenticated}>
            <LeaderboardScreen />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
