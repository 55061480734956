import { Box, Button, Divider, Typography } from '@mui/material';
import { DocumentData } from 'firebase/firestore';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { updateIsAnonymousResultsFirebase } from '../firebase/db/results';
import { getCurrentResults } from '../store/resultsSlice';
import { getCurrentUser } from '../store/userSlice';
import { shortenString } from '../utilities/functionality/stringHandlers';
import { Competition } from '../utilities/types';
import CustomSwitch from './CustomSwitch';
import SimpleCard from './SimpleCard';
import DialogCompetitionLeave from './dialogs/DialogCompetitionLeave';

interface UserCompetitionDetailsCardProps {
  competition: Competition | DocumentData | undefined;
  onCloseSettingsDrawer: () => void;
}

const UserCompetitionDetailsCard: React.FC<UserCompetitionDetailsCardProps> = ({
  competition,
  onCloseSettingsDrawer,
}) => {
  const currentUser = useSelector(getCurrentUser);
  const currentResults = useSelector(getCurrentResults);
  const [displayDialog, setDisplayDialog] = useState<boolean>(false);
  const [isAnonymous, setIsAnonymous] = useState<boolean>(currentResults?.isAnonymous);

  const handleToggleAnonymous = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentUser?.uid) {
      setIsAnonymous(event.target.checked);
      updateIsAnonymousResultsFirebase({ userId: currentUser?.uid, isAnonymous: event.target.checked });
    }
  };

  return (
    <>
      <DialogCompetitionLeave
        title={t('title_confirm_leave_competition')}
        content={t('dialog_confirm_leave_competition')}
        displayDialog={displayDialog}
        setDisplayDialog={setDisplayDialog}
        currentResults={currentResults}
        currentUser={currentUser}
      />

      <SimpleCard styles={{ padding: 4 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="caption">{t('label_active_competition')}</Typography>
          <Typography variant="body1" fontWeight="bold">
            {shortenString(competition?.competitionName, 17)}
          </Typography>

          <Divider sx={{ mt: 2, mb: 2 }} />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="body1">{t('settings_label_anonymous')}</Typography>
            <CustomSwitch
              checked={isAnonymous}
              onChange={handleToggleAnonymous}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>

          <Button
            data-testid="button-leave-competition"
            size="small"
            variant="outlined"
            color="error"
            sx={{
              mt: 6,
              mb: 2,
            }}
            onClick={() => {
              onCloseSettingsDrawer();
              setDisplayDialog(true);
            }}
          >
            {t('button_leave_competiton')}
          </Button>
        </Box>
      </SimpleCard>
    </>
  );
};

export default UserCompetitionDetailsCard;
