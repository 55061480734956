import { Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';

import SimpleCard from '../../../components/SimpleCard';
import { getCurrentResults } from '../../../store/resultsSlice';
import { getPointsOnlyArrays, getSumPointsOnlyArrays, getTotalPoints } from './achievementsMethods';

interface AchievementsPointsCardProps {}

const AchievementsPointsCard: React.FC<AchievementsPointsCardProps> = () => {
  const currentResults = useSelector(getCurrentResults);

  const pointsOnlyArrays = getPointsOnlyArrays(currentResults);
  const sumPointsOnlyArrays = getSumPointsOnlyArrays(pointsOnlyArrays);
  const totalPoints = getTotalPoints(sumPointsOnlyArrays);

  return (
    <SimpleCard
      styles={{
        flexGrow: 1,
      }}
    >
      <Typography
        data-testid="text-results-points"
        component="h2"
        variant="h4"
        color="primary"
        sx={{ fontWeight: 700 }}
      >
        {totalPoints}
      </Typography>
      <Typography color="primary" variant="caption">
        {t('label_points')}
      </Typography>
    </SimpleCard>
  );
};

export default AchievementsPointsCard;
