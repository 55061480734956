import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { DocumentData } from 'firebase/firestore';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { translateDay } from '../../../translations/utils/datesTranslations';
import { getTodaysDate } from '../../../utilities/functionality/timeHandlers';
import { Competition } from '../../../utilities/types';

interface AssignmentCalendarTabsProps {
  activeDayIndex: number;
  lastDayIndex: number;
  dayChange: (newValue: number) => void;
  competition: Competition | DocumentData | null;
}

const AssignmentCalendarTabs = ({
  activeDayIndex,
  lastDayIndex,
  dayChange,
  competition,
}: AssignmentCalendarTabsProps) => {
  const { i18n } = useTranslation();

  const todaysDate = getTodaysDate(competition?.startDate, activeDayIndex);

  return (
    <>
      <Box sx={{ display: 'flex', gap: 0, alignItems: 'center', mb: 0, mt: 0, p: 0 }}>
        <IconButton
          sx={{ p: 0 }}
          onClick={() => {
            if (activeDayIndex !== 0) {
              dayChange(activeDayIndex - 1);
            }
          }}
        >
          <ChevronLeftIcon
            data-testid="button-assignments-previous-day"
            aria-label="next day"
            fontSize="large"
            sx={{ color: activeDayIndex !== 0 ? 'primary.main' : 'custom.disabled' }}
          />
        </IconButton>

        <Box
          sx={{
            ml: 0,
            mr: 0,
            pl: 0,
            pr: 0,
            pt: 2.25,
            pb: 2.25,
            minWidth: 78,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 0,
            borderRadius: 1,
          }}
        >
          {activeDayIndex !== -1 && (
            <Typography variant="body1" fontSize={18}>
              {translateDay(dayjs(Date.parse(todaysDate)).format('dd'), i18n.language)}{' '}
              {dayjs(Date.parse(todaysDate)).format('D')}
            </Typography>
          )}
        </Box>

        <IconButton
          onClick={() => {
            if (activeDayIndex !== lastDayIndex) {
              dayChange(activeDayIndex + 1);
            }
          }}
          sx={{ p: 0 }}
        >
          <ChevronRightIcon
            fontSize="large"
            aria-label="next day"
            data-testid="button-assignments-next-day"
            sx={{ color: activeDayIndex !== lastDayIndex ? 'primary.main' : 'custom.disabled' }}
          />
        </IconButton>
      </Box>
    </>
  );
};

export default AssignmentCalendarTabs;
