import {
  DocumentData,
  DocumentReference,
  QuerySnapshot,
  WithFieldValue,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';

import { db } from '../firebaseConfig';

/**
 *
 * Fetch single competition
 *
 */
export const getCompetitionFirebase = async (competitionId: string): Promise<DocumentData | undefined> => {
  const competitionSnap = await getDoc(doc(db, 'competitions', competitionId));

  if (competitionSnap?.exists() && competitionSnap.data()) {
    return competitionSnap.data();
  }

  return undefined;
};

/**
 *
 * get all competitions which are groups
 */
export const getCompetitionsFirebase = async (): Promise<QuerySnapshot<DocumentData> | undefined> => {
  const queryCompetitions = query(collection(db, 'competitions'));
  const querySnapshotCompetitions = await getDocs(queryCompetitions);

  if (!querySnapshotCompetitions.empty) {
    return querySnapshotCompetitions;
  }

  return undefined;
};

/**
 *
 * get all competitions which are groups
 */
export const getOwnedCompetitionFirebase = async (
  userId: string | null | undefined,
): Promise<QuerySnapshot<DocumentData> | undefined> => {
  const queryCompetitions = query(collection(db, 'competitions'), where('ownerId', '==', userId));
  const querySnapshotCompetitions = await getDocs(queryCompetitions);

  if (!querySnapshotCompetitions.empty) {
    return querySnapshotCompetitions;
  }

  return undefined;
};

/**
 *
 * Add a new competition
 *
 */
export const addNewCompetitionFirebase = async (
  competition: WithFieldValue<DocumentData>,
): Promise<DocumentReference<DocumentData> | undefined> => {
  const newCompetitionRef = await addDoc(collection(db, 'competitions'), competition);

  if (newCompetitionRef) {
    return newCompetitionRef;
  }

  return undefined;
};

/**
 *
 * Set custom assignment
 *
 */
type NewCompetitionData = {
  competitionID: string;
  competition: WithFieldValue<DocumentData>;
};

export const setNewCompetitionFirebase = async ({
  competitionID,
  competition,
}: NewCompetitionData): Promise<string> => {
  await setDoc(doc(db, 'competitions', competitionID), {
    ...competition,
  });

  return 'competition set';
};

/**
 *
 * Update existing competitions
 *
 */
type NewUpdatedCompetitionData = {
  nrOfParticipants: number;
  competitionId: string;
};

export const updateCompetitionNrOfParticipantsFirebase = async ({
  nrOfParticipants,
  competitionId,
}: NewUpdatedCompetitionData): Promise<string> => {
  await updateDoc(doc(db, 'competitions', competitionId), {
    nrOfParticipants: nrOfParticipants,
  });

  return 'nrOfParticipants updated';
};

/**
 *
 * Update existing competitions
 *
 */
type NewUpdatedCompetitionTeams = {
  teams: string[];
  competitionId: string;
};

export const updateCompetitionTeamsFirebase = async ({
  teams,
  competitionId,
}: NewUpdatedCompetitionTeams): Promise<string> => {
  await updateDoc(doc(db, 'competitions', competitionId), {
    teams: teams,
  });

  return 'teams updated';
};
