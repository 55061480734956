import { Box, ListItem, ListItemText, Typography } from '@mui/material';
import { DocumentData } from 'firebase/firestore';
import { t } from 'i18next';
import React from 'react';

import { Results } from '../../../utilities/types';

interface LeaderboardTeamItemProps {
  length: number;
  index: number;
  currentResults: Results | DocumentData;
  nrOfDaysSubmitted?: number;
  score?: number;
  team: string;
  noBorder: boolean;
  scoreMode: string;
  averageScore: number;
}

const LeaderboardTeamItem: React.FC<LeaderboardTeamItemProps> = ({
  index,
  length,
  team,
  currentResults,
  nrOfDaysSubmitted,
  score,
  averageScore,
  noBorder,
  scoreMode,
}) => {
  // const medals: Record<string, { border: string }> = {
  //   '1': { border: '5px solid #f3d032' },
  //   '2': { border: '5px solid #d9dadc' },
  //   '3': { border: '5px solid #f3ae8b' },
  // };

  return (
    <ListItem
      sx={{
        bgcolor: index % 2 === 0 ? 'background.paper' : 'custom.listItem',
        borderBottom: noBorder ? 'none' : '1px solid',
        borderColor: 'custom.paperBorder',
        pl: 3.5,
        pr: 6,
        borderRadius: `${index === 0 ? 12 : 0}px ${index === 0 ? 12 : 0}px ${index + 1 === length ? 12 : 0}px ${
          index + 1 === length ? 12 : 0
        }px`,
      }}
    >
      <Box
        sx={{
          borderRadius: 10,
          width: 30,
          height: 30,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mr: 4,
          // ...medals[`${index + 1}`],
        }}
      >
        <Typography variant="body2" sx={{ color: 'text.primary', fontWeight: 700, lineHeight: 1 }}>{`${
          index + 1
        }`}</Typography>
      </Box>

      <ListItemText
        primary={team}
        primaryTypographyProps={{
          sx: { fontWeight: currentResults?.team === team ? 700 : 400, color: 'text.primary' },
        }}
        secondaryTypographyProps={{
          sx: {
            fontSize: '0.7rem',
            fontWeight: currentResults?.team === team ? 700 : 400,
          },
        }}
        secondary={`${t('label_submitted_days')}: ${nrOfDaysSubmitted}`}
      />

      <Box display="flex" flexDirection="column">
        <Typography
          component="span"
          variant="body1"
          data-testid={`text-leaderboard-score-${index}`}
          sx={{ fontWeight: currentResults?.team === team ? 700 : 400 }}
          color="primary"
        >
          {`${scoreMode === 'average' ? averageScore : score}p`}
        </Typography>
      </Box>
    </ListItem>
  );
};

export default LeaderboardTeamItem;
